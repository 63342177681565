@import "~assets/scss/variables";

.app-layout-content-header {
  background: $layout-content-header-background;
  box-shadow: 1px 0 6px #d2d2d2;
  min-height: $layout-content-header-height;
  display: flex;
  align-items: center;

  .back-button {
    margin-right: 5px;
    color: $text-color;
    transition: color 0.3s;

    &:hover {
      color: $icon-hover;
    }
  }

  .content-header-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0 15px;
  }

  .content-header-buttons {
    margin: 0 15px 0 auto;
  }
}

.app-layout-content {
  -ms-flex: auto;
  flex: auto;
  height: calc(100% - #{$layout-content-header-height});
  width: 100%;
  overflow: auto;

  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    min-width: 280px;
    padding: 7.5px;
  }

  .content {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 250px;
    // max-width: 1200px;
    padding: 15px;
    margin: 7.5px;
    background-color: $layout-content-background;
  }
}
@media (min-width: 684px) {
  .eventNav {
    margin-left: auto !important;
    margin-right: auto !important;
    min-width: 240px !important;
    line-height: 25px !important;
  }
}
@media (min-width: 632px) {
  .menuNav {
    margin-left: auto !important;
    margin-right: auto !important;
    min-width: 160px !important;
    line-height: 25px !important;
  }
}

.menuNav {
  line-height: 25px !important;
}

.eventNav {
  line-height: 15px !important;
}

.padding-icon {
  padding: 0.4rem !important;
}

.menuItem {
  color: black;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  color: black !important;
  border-bottom: 2px solid #ba2d2d !important;
}

.red-underline:active {
  border-bottom: 2px !important;
}
