@import "~assets/scss/variables";

#app-root, .app-layout-outer, .app-layout-inner {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 0;
  height: 100%;
}

.app-layout-outer {
  position: relative;
  -ms-flex-direction: row;
  flex-direction: row;
}

.app-layout-inner {
  position: relative;
  overflow: auto;
  background-color: #eff3f4;

  &:not(.no-sidebar) {
    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      margin-left: 50px;
    }
  }

  .app-layout-spinner {
    height: calc(100% - #{$layout-footer-height});
  }
}
