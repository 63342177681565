// Base Variables
$color-white: #fff;
$color-black: #213443;
$color-dark-gray: #c2c2c2;
$color-blue: #366f9a;
$color-blue-light:#92bcc1;
$color-green: #51952e;
$color-green-light: #abcb9c;
$color-orange: #d57b3d;
$color-orange-light: #ebbfa2;
$color-red: #ba2d2d;
$color-red-light: #e09a99;
$color-teal: #1b8b8c;
$color-text: #564f4f;
$font-size: 13px;
$font-small: 0.8rem;
$font-medium: 0.9rem;
$font-normalt: 1rem;
$font-large: 1.4rem;
// App Variables
$icon-active: $color-orange;
$icon-hover: $color-orange;
$layout-content-background: $color-white;
$layout-content-header-background: $color-white;
$layout-content-header-height: 60px;
$layout-footer-height: 40px;
$layout-header-background: #f8f9fa;
$layout-header-height: 60px;
$layout-sidebar-background: #1b2a35;
$layout-sidebar-text-color: $color-white;
$layout-sidebar-active-color: $color-orange;

// branding colors
$eventops-red: #ba2d2d;
$eventops-orange: #d57b3d;
$eventops-blue: #366f9a;
$eventops-green: #51952e;
$eventops-teal: #1b8b8c;
$eventops-black: #213443;

// TODO
$text-color: #564f4f;
$white: #fff;
$red: #ba2d2d;
$base-font-size: 13px;
$small-font: 0.8rem;
$medium-font: 0.9rem;
$normal-font: 1rem;
$large-font: 1.4rem;
$full-area: 100%;
$blue-flow: #1ba095;
$red-dark: #9e2424;
$orange: #d57b3d;
$orange-dark: #a75923;
$sidemenu-color: #1b2a35;
$medium-gray: #ebebeb;
$light-gray: #e7e7e7;
$dark-gray: #c2c2c2;
$body-color: #eff3f4;
$green: #28a745;
$green-dark: #51952e;
$blue: #366f9a;
$teal: #1b8b8c;
$black: #213443;
$active-tab: #f7f7f9;
$line-color: #eaeaea;
$bg-white: #fff;
$light-bg: #f9f9f9;
$link-active: #47d0ab;
$textbox-border: #ced4da;
$popupbg: #f6f6f6;
$color-d-5: #d5d5d5;
$light-bg-1: #fcfcfc;
$light-bg-2: #eeeeee;
$white-a: #fafafa;
$light-gray-d: #dddddd;
$primary-color: #ba2d2d;
$inProgress: #cf6f36;
$white-7: #f7f7f7;
$approval-flow-item: #3a3b48;
$approval-flow-line: #a0a9bd;
$light-icons: #a4adb6;
$accordion-header: #1e2e3b;
$black-89: #898989;
$approved: #51952e;
// $planning: #E46A6A;
$planning: #51952e;
