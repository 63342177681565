@import "~assets/scss/variables";

.app-layout-footer {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $layout-footer-height;
  border-top: 1px solid #e2e2e2;

  .footer-content {
    margin-bottom: 0;
  }
}
