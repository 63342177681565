@import '~assets/scss/_variables.scss';

.link {
  color: inherit;

  &:hover {
    color: inherit;
    font-weight: 600;
    text-decoration: none;
  }
}

.icon-bool-true {
  color: $color-green !important;
  background-color: transparent;
}

.icon-bool-false {
  color: $color-red !important;
  background-color: transparent;
}
