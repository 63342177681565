@import "~assets/scss/variables";

.dialog {
  .ant-modal-header {
    padding: 0;
  }

  .ant-modal-body {
    padding: 16px 16px 6px 16px !important;
  }

  .dialog-title {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-black;
    color: $color-white;
  }

  .dialog-close {
    color: $color-white;
    background-color: transparent;
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
