@import "~assets/scss/variables";

.form-group .form-control {
  color: $color-text;
  font-weight: 500;

  &::placeholder {
    color: $color-dark-gray;
    opacity: 1;
  }
}

.custom-field-file .ant-upload.ant-upload-select {
  display: block;
}

.field-hidden {
  margin: 0 -5px 13px -5px;
  padding: 5px;
  border: 2px dashed $color-dark-gray;
  border-radius: 4px;
}

.input-loading {
  position: relative;

  .spinner {
    left: unset;
    right: 4px;
    height: calc(1.5em - 8px + 0.75rem);
    width: calc(1.5em - 8px + 0.75rem);
  }
}

.ant-select-arrow .spinner {
    left: unset;
    right: -4px;
    height: calc(1.5em - 8px + 0.75rem);
    width: calc(1.5em - 8px + 0.75rem);
  }

.color-picker {
  margin-left: -1px;

  .color-picker-preview {
    height: 100%;
    width: 2.8rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer
  }

  .color-picker-popover {
    position: absolute;
    top: -300px;
    right: 0;
    z-index: 102;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  }

  .color-picker-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
