@import "~assets/scss/variables";


.add-button, .save-button {
  color: $color-green !important;
  background-color: transparent;
}

.cancel-button {
  color: $color-red !important;
  background-color: transparent;
}

.danger-button {
  color: $color-white !important;
  background-color: $color-red !important;
}
