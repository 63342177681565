@import "~assets/scss/variables";


.app-layout-header {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: $layout-header-height;
  background-color: $layout-header-background;
  box-shadow: 0px 1px 3px #efefef;
  z-index: 1;

  .menu-icon {
    padding: .25rem;
    font-size: 18px;
    transition: color 0.3s;
    stroke: $text-color;
    stroke-width: 50;

    &:hover {
      color: $icon-hover;
      stroke: $icon-hover;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.3125rem 0;
    margin: 0 1rem 0 0.5rem;
    white-space: nowrap;
    color: inherit !important;
    font-size: 2rem;

    img {
      max-height: 100%;
      max-width: 250px;
      vertical-align: bottom;
    }
  }
  // TODO: CHECK
  .notification {
    position: relative;
    flex-direction: row;
    align-items: center;

    i {
      font-size: 1.5rem
    }

    .notificationCount {
      position: absolute;
      top: -.3rem;
      right: -.3rem;
    }

    .dropdown-menu {
      width: 30rem;
      padding: 0;
      position: absolute;

      .dropdown-item {
        .message {
          .avatar {
            width: 40px;
            height: 40px;

            img {
              max-height: 100%;
            }
          }

          .message-content {
            width: calc(100% - 54px);
          }

          .message-title {
            width: calc(100% - 80px);
          }
        }

        &:active {
          background-color: #f8f9fa;
        }
      }

      .dropdown-header {
        background-color: #f8f9fa;
        padding: 1rem;
      }

      .dropdown-content {
        max-height: 350px;
        overflow-y: auto;
      }

      .dropdown-footer {
        background-color: #f8f9fa;
        padding: 1rem;
        cursor: pointer;
      }

      @media only screen and (max-width: 520px) {
        width: 21rem;
      }
    }
  }

  .user-profile {
    display: flex;
    align-items: center;
    height: 85px;
    padding: .5rem;
    white-space: nowrap;

    .user-image {
      flex-shrink: 0;
      width: 37px;
      height: 37px;
      object-fit: cover;
      border-radius: 50%;
      background: $color-white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      font-size: 24px;
      text-align: center;
    }

    .user-info {
      margin-left: .5rem;
    }
  }
}
