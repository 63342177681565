@import "~assets/scss/variables";

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: black;
  background-image: url(~assets/images/loginBackground.png);
  background-position: center;
  background-size: cover;

  .login-content {
    width: 400px;

    @media only screen and (max-width: 420px) {
      width: 320px;
    }

    p {
      color: $color-white;
      text-align: center;
      margin-top: 5px;
    }

    .login-logo {
      height: 65px;
      text-align: center;
      margin-bottom: 15px;

      img {
        max-height: 100%;
      }
    }

    .login-form {
      input[type="text"], input[type="password"] {
        width: 100%;
        background-color: #202020;
        border: none;
        height: 36px;
        margin-top: 15px;
        color: $color-white;
        padding-left: 10px;
      }

      .forgot-password-area {
        margin-top: 10px;

        a {
          color: $color-white;
          text-decoration: none;
          font-size: $font-small;

          &.disabled {
            cursor: progress;
          }
        }

        label {
          color: $color-white;
          margin-left: 5px;
          cursor: pointer;
          font-size: $font-small;
        }
      }

      .login-button {
        background-color: $color-red;
        padding: 3px 35px 5px 30px;
        border: none;
        float: right;
        color: $color-white;
        margin-top: 15px;
        height: 30px;

        &:disabled {
          cursor: progress;
        }

        &.forgot-password {
          margin-top: 5px;
        }
      }

      .login-button-arrow {
        float: right;
        height: 34px;
        width: 34px;
        background-color: $color-white;
        border-radius: 50%;
        position: relative;
        margin-left: -18px;
        margin-top: 14px;
        border: solid 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        cursor: pointer;

        &.disabled {
          cursor: progress;
        }

        &.forgot-password {
          margin-top: 4px;
        }
      }
    }
  }

  .login-footer {
    position: absolute;
    width: 100%;
    height: 5%;
    bottom: 0;
    left: 0;
    text-align: center;
    color: $color-white;
  }
}
