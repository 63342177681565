@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');
@import "~bootstrap/scss/bootstrap";
@import '~antd/dist/antd.css';
@import "~assets/scss/variables";

body,
html {
  margin: 0;
  padding: 0;
  font-size: $font-size;
  font-family: 'Montserrat', sans-serif !important;
  height: 100%;
  width: 100%;
  font-weight: 500 !important;
  color: $color-text !important;
}
