
.app-not-found {
  text-align: center;

  .icon-not-found {
    font-size: 250px;
    margin: 25px 0
  }

  .text-404 {

  }
}
