@import "~assets/scss/variables";

.notification-success {
  min-height: 80px;
  background: $color-green-light !important;

  svg {
    color: $color-white;
    margin-left: -13px;
  }
}

.notification-info {
  min-height: 80px;
  background: $color-blue-light !important;

  svg {
    color: $color-white;
    margin-left: -13px;
  }
}

.notification-warning {
  min-height: 80px;
  background: $color-orange-light !important;

  svg {
    color: $color-white;
    margin-left: -13px;
  }
}

.notification-error {
  min-height: 80px;
  background: $color-red-light !important;

  svg {
    color: $color-white;
    margin-left: -13px;
  }
}
