@import "~assets/scss/variables";

.app-layout-sidebar, .app-layout-sidebar-collapsed {
  height: 100%;
  width: 250px;
  flex-shrink: 0;
  background-color: $layout-sidebar-background;
  position: relative;
  -webkit-transition: width 0.8s;
  transition: width 0.8s;
  overflow: hidden;
  z-index: 101;

  @media only screen and (max-width: 1024px) {
    position: absolute;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  .user-profile {
    display: flex;
    align-items: center;
    height: 85px;
    padding: .5rem;
    white-space: nowrap;

    .user-image {
      flex-shrink: 0;
      width: 37px;
      height: 37px;
      object-fit: cover;
      border-radius: 50%;
      background: $color-white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      font-size: 24px;
      text-align: center;
    }

    .user-info {
      margin-left: .5rem;
      color: $layout-sidebar-text-color;
    }
  }

  .sidebar-menu {
    height: calc(100% - 135px);
    padding: 0 .5rem 0 0;
    margin: 0;
    overflow-y: auto;

    .link {
      display: inline-flex;
      width: 100%;
    }

    .menu-item-collapse-icon {
      width: 25px;
      text-align: center;
    }

    .menu-item {
      width: 100%;
      color: $layout-sidebar-text-color;
      padding: 1rem 0;
      position: relative;
      white-space: nowrap;
      list-style: none;

      &.menu-item-active {
        color: $layout-sidebar-active-color;
      }

      .link {
        min-width: 50px;
      }

      .menu-item-icon {
        width: 50px;
        display: inline-block;
        text-align: center;
      }

      .menu-item-label {
        max-width: calc(100% - 50px);
        overflow: hidden;
        font-size: 1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .menu-sub-items, .menu-sub-items-collapsed {
      max-height: 100%;
      width: 100%;
      padding: 0 0 0 2.5rem;
      overflow: hidden;
      color: $layout-sidebar-text-color;
      position: relative;
      white-space: nowrap;
      list-style: none;
      -webkit-transition: max-height 0.35s ease;
      transition: max-height 0.35s ease;

      .menu-sub-item {
        max-width: 100%;
        padding: .25rem 0;

        &.menu-sub-item-active {
          color: $layout-sidebar-active-color;
        }

        .menu-sub-item-icon {
          width: 25px;
          display: inline-block;
          text-align: center;
        }

        .menu-sub-item-label {
          max-width: calc(100% - 25px);
          overflow: hidden;
          font-size: 1rem;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .menu-sub-item-collapse-icon {
          width: 25px;
          text-align: center;
        }
      }
    }

    .menu-sub-items-collapsed {
      max-height: 0;
    }
  }

  .sidebar-logo {
    display: flex;
    align-items: center;
    height: 50px;
    width: 50%;
    max-width: 125px;
    margin: 0 auto;

    img {
      height: auto;
      max-width: 100%;
    }
  }
}

.app-layout-sidebar {

  .sidebar-menu {
    padding: 0 .5rem 0 0;

    .link.link-parent {
      width: calc(100% - 25px);
    }

    .menu-item-label, .menu-item-collapse-icon {
      display: inline-block;
    }
  }
}

.app-layout-sidebar-collapsed {
  width: 50px;

  @media only screen and (max-width: 480px) {
    width: 0;
  }

  .user-profile .user-info {
    display: none;
  }

  .sidebar-menu {
    padding: 0;

    .menu-item-label, .menu-item-collapse-icon {
      display: none;
    }

    .menu-sub-items, .menu-sub-items-collapsed {
      display: none;
    }
  }

  .sidebar-logo {
    display: none;
  }
}
